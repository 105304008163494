<template>
  <div v-if="isShow" class="c-modal lg">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__content">
      <div class="c-modal__head">
        <h5 class="c-lead-s">最終処分場設定</h5>
        <span class="c-modal__head__close" @click="closeModalHandler"></span>
      </div>
      <div class="c-modal__body">
        <table class="c-modal__table">
          <thead>
            <tr>
              <th> </th>
              <th>最終処分場名</th>
              <th>住所</th>
              <th>事業場コード</th>
            </tr>
          </thead>
          <tbody class="c-modal__body__finalDisposalSiteList" v-if="finalDisposalSiteList.length">
            <tr v-for="finalDisposalSite in finalDisposalSiteList" :key="finalDisposalSite.finalDisposalSiteId">
              <td class="c-checkbox is-checkbox">
                <input
                  :id="'finalDisposalSite-cb-' + finalDisposalSite.finalDisposalSiteId"
                  type="checkbox"
                  :value="finalDisposalSite"
                  v-model="selectedFinalDisposalSite"
                />
                <label
                  class="c-checkbox__label"
                  :for="'finalDisposalSite-cb-' + finalDisposalSite.finalDisposalSiteId"
                >
                  <span class="c-checkbox__box"></span>
                </label>
              </td>
              <td>{{ dottedText(finalDisposalSite.finalDisposalSiteName, 15) }} </td>
              <td>{{ dottedText((finalDisposalSite.addressInfo ? finalDisposalSite.addressInfo.prefecturesName : "") + (finalDisposalSite.addressInfo ? finalDisposalSite.addressInfo.address : "") + (finalDisposalSite.addressInfo ? finalDisposalSite.addressInfo.buildingName : ""), 30) }} </td>
              <td>{{ dottedText(finalDisposalSite.finalDisposalStoreCode ? finalDisposalSite.finalDisposalStoreCode : "-", 30) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="c-modal__foot">
        <div class="c-btn primary small" @click="clickSetHandler">設定</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import common from "@/mixin/common";
import validation from "@/mixin/validation";
import {API_URL} from "@/const";

export default {
  props: {
    isShow: {
      type: Boolean,
    },
    entrustId: {
      type: Number,
    },
    default: {
      type: Array,
    }
  },
  mixins: [common, validation],
  data() {
    return {
      finalDisposalSiteList: [],
      selectedFinalDisposalSite: [],
    };
  },

  methods: {
    closeModalHandler() {
      this.$emit("update:isShow", false);
      this.finalDisposalSiteList = [];
    },

    clickSetHandler() {
      this.$emit("setselectedFinalDisposalSite", this.selectedFinalDisposalSite);
      this.$emit("update:isShow", false);
    },

    // 最終処分場選択肢取得API
    getFinalDisposalSiteSelectListApi() {
      axios.get(API_URL.ROUTE.SELECT_FINAL_DISPOSALSITE + this.entrustId)
        .then((res) => {
          this.finalDisposalSiteList = res.data.finalDisposalSiteSelectList;
          this.selectedFinalDisposalSite = this.finalDisposalSiteList.filter(sl => {
            return this.selectedFinalDisposalSite.find(l => l.finalDisposalSiteId === sl.finalDisposalSiteId);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  watch: {
    isShow() {
      if (this.isShow) {
        this.getFinalDisposalSiteSelectListApi();
      }
    },
    default() {
      this.selectedFinalDisposalSite = [];
      this.default.forEach(l => {
        this.selectedFinalDisposalSite.push({
          finalDisposalSiteId : l.finalDisposalSiteId,
          finalDisposalSiteName : l.finalDisposalSiteName,
          addressInfo : l.addressInfo,
          finalDisposalStoreCode : l.finalDisposalStoreCode,
        });
      });
    }
  },
};
</script>
