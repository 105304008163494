<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="ルート編集" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="l-flex l-item">
          <div class="c-form__reqTxt l-item">：入力必須</div>
          <div class="c-collectorText">
            <span>お気に入り</span>
            <div
              v-if="isFavorite"
              @click="toggleFavorite()"
              class="c-btn fav on"
            />
            <div v-else @click="toggleFavorite()" class="c-btn fav off" />
          </div>
        </div>
        <!--基本情報-->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">基本情報</h3>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>ルート番号</label>
            </div>
            <div class="c-inputWrap__items">{{ routeInfo.routeId | blankText }}</div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>ルート名称</label>
            </div>
            <div class="c-inputWrap__items">
              <InputText
                :value.sync="formEdit.routeName"
                inputType="text"
                tooltip="ルート名称を入力してください"
                :validation="validateJustBlank"
                :isValid.sync="validate.routeName.isValid"
                :errMsg.sync="validate.routeName.errMsg"
              />
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>排出事業場名</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.generateStoreInfo.name | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>事業場任意設定</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <div class="c-radio">
                  <input
                    id="change-store-true"
                    type="radio"
                    :value="true"
                    v-model="formEdit.isChangeStore"
                  />
                  <label class="c-radio__label" for="change-store-true">
                    <span class="c-radio__box"></span>有
                  </label>
                </div>
                <div class="c-radio">
                  <input
                    id="change-store-false"
                    type="radio"
                    :value="false"
                    v-model="formEdit.isChangeStore"
                  />
                  <label class="c-radio__label" for="change-store-false">
                    <span class="c-radio__box"></span>無
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>廃棄物区分</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.wasteTypeInfo.name | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>マニフェスト区分</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.manifestTypeInfo.name | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>廃棄物種類</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.jwnetItemTypeInfo.name | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>CBA品目</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.cbaItemInfo.name | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>構成種類</label>
            </div>
            <div class="c-inputWrap__items">
              {{ listItem(routeInfo.constitutionItemInfo, "name") | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>廃棄物名称</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.wasteNameInfo.name | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>詳細名称</label>
            </div>
            <div class="c-inputWrap__items">
              <InputText
                :value.sync="formEdit.detailWasteName"
                placeholder="詳細名称"
                tooltip="30文字以内で入力してください"
                :validation="validateMaxLen"
                :validateArg="30"
                :isValid.sync="validate.detailWasteName.isValid"
                :errMsg.sync="validate.detailWasteName.errMsg"
              />
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>予定数量</label>
            </div>
            <div class="c-inputWrap__items__cols u-mgl10">
              <InputText
                :value.sync="formEdit.plansQuantity"
                class="sm"
                inputType="number"
                tooltip="整数5桁、小数点1桁、小数部3桁で入力してください"
                :validation="validateQuantity"
                :isValid.sync="validate.plansQuantity.isValid"
                :errMsg.sync="validate.plansQuantity.errMsg"
              />
              <Selectbox :value.sync="formEdit.plansWasteUnitTypeId" class="sm">
                <option value=""></option>
                <option
                  v-for="wasteUnitType of wasteUnitTypes"
                  :key="wasteUnitType.id"
                  :value="wasteUnitType.id"
                >
                  {{ wasteUnitType.name }}
                </option>
              </Selectbox>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>上限数量</label>
            </div>
            <div class="c-inputWrap__items l-col3">
              <InputText
                :value.sync="formEdit.limitQuantity"
                class="sm"
                inputType="number"
                tooltip="半角数値で入力してください"
                :validation="validateNumber"
                :isValid.sync="validate.limitQuantity.isValid"
                :errMsg.sync="validate.limitQuantity.errMsg"
              />
              <Selectbox :value.sync="formEdit.limitWasteUnitTypeId" class="sm">
                <option value=""></option>
                <option
                  v-for="wasteUnitType of wasteUnitTypes"
                  :key="wasteUnitType.id"
                  :value="wasteUnitType.id"
                >
                  {{ wasteUnitType.name }}
                </option>
              </Selectbox>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>数量確定者</label>
            </div>
            <div class="c-inputWrap__items">
              <Selectbox :value.sync="formEdit.quantityConfirmBusinessTypeId">
                <option value=""></option>
                <option
                  v-for="quantityConfirmMember of quantityConfirmMembers"
                  :key="quantityConfirmMember.id"
                  :value="quantityConfirmMember.id"
                >
                  {{ quantityConfirmMember.name }}
                </option>
              </Selectbox>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>荷姿</label>
            </div>
            <div class="c-inputWrap__items">
              <Selectbox :value.sync="formEdit.shapeTypeId">
                <option value=""></option>
                <option
                  v-for="shapeType of shapeTypes"
                  :key="shapeType.id"
                  :value="shapeType.id"
                >
                  {{ shapeType.name }}
                </option>
              </Selectbox>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>荷姿数量</label>
            </div>
            <div class="c-inputWrap__items">
              <InputText
                :value.sync="formEdit.shapeQuantity"
                inputType="number"
                :validation="validateJustBlank"
                :isValid.sync="validate.shapeQuantity.isValid"
                :errMsg.sync="validate.shapeQuantity.errMsg"
              />
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>処分方法</label>
            </div>
            <div class="c-inputWrap__items">
              <Selectbox :value.sync="formEdit.disposalWayTypeId">
                <option value=""></option>
                <option
                  v-for="disposalWayType of disposalWayTypes"
                  :key="disposalWayType.id"
                  :value="disposalWayType.id"
                >
                  {{ disposalWayType.name }}
                </option>
              </Selectbox>
            </div>
          </div>

          <div v-if="routeInfo.jwnetItemTypeInfo.isHarmful" class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>有害物質情報</label>
            </div>
            <div class="c-inputWrap__items">
              <div
                v-for="(harmfulItem, itemIndex) in formEdit.harmfulInfo"
                :key="`hmfl-item-${itemIndex}`"
                class="l-flex start"
              >
                <Selectbox
                  class="l-area"
                  name="package-name"
                  :value.sync="harmfulItem.harmfulTypeId"
                  :validation="validateJustBlank"
                >
                  <option
                    v-for="(harmfulType, index) in harmfulTypes"
                    :key="'hmfl-' + index"
                    :value="harmfulType.id"
                  >
                    {{ harmfulType.name }}
                  </option>
                </Selectbox>
                <div
                  class="c-input__addBtn c-btn secondary delete sm"
                  @click="removeHarmfulTypeHandler(harmfulItem)"
                >
                  削除
                </div>
              </div>
              <div class="c-btn secondary add sm" v-if="formEdit.harmfulInfo && formEdit.harmfulInfo.length < 6" @click="addHarmfulTypeHandler">
                有害物質名を追加
              </div>
            </div>
          </div>

          <div class="c-inputWrap"
               v-for="(collectUnitPrice, index) in formEdit.collectUnitPriceList"
               :key="'collectUnitPrice-' + index">
            <div class="c-inputWrap__label">
              <label>
                運搬単価（区間{{ collectUnitPrice.sectionNo }}）
              </label>
            </div>
            <div class="c-inputWrap__items l-col3">
              <InputText
                  :value.sync="collectUnitPrice.collectUnitPrice"
                  class="sm"
                  inputType="number"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberOrFloat"
                  :isValid.sync="validate.collectUnitPriceList[index].isValid"
                  :errMsg.sync="validate.collectUnitPriceList[index].errMsg"
              /><Selectbox
                :value.sync="collectUnitPrice.collectPriceWasteUnitTypeId"
                class="sm"
            >
              <option value=""></option>
              <option
                  v-for="wasteUnitType of wasteUnitTypes"
                  :key="wasteUnitType.id"
                  :value="wasteUnitType.id"
              >
                {{ wasteUnitType.name }}
              </option></Selectbox
            >
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>処分単価</label>
            </div>
            <div class="c-inputWrap__items l-col3">
              <InputText
                :value.sync="formEdit.disposalUnitPrice"
                class="sm"
                inputType="number"
                tooltip="半角数値で入力してください"
                :validation="validateNumberOrFloat"
                :isValid.sync="validate.disposalUnitPrice.isValid"
                :errMsg.sync="validate.disposalUnitPrice.errMsg"
              /><Selectbox
                :value.sync="formEdit.disposalPriceWasteUnitTypeId"
                class="sm"
                >
                <option value=""></option>
                <option
                  v-for="wasteUnitType of wasteUnitTypes"
                  :key="wasteUnitType.id"
                  :value="wasteUnitType.id"
                >
                  {{ wasteUnitType.name }}
                </option></Selectbox
              >
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>有価単価</label>
            </div>
            <div class="c-inputWrap__items l-col3">
              <InputText
                :value.sync="formEdit.valuablesUnitPrice"
                class="sm"
                inputType="number"
                tooltip="半角数値で入力してください"
                :validation="validateNumberOrFloat"
                :isValid.sync="validate.valuablesUnitPrice.isValid"
                :errMsg.sync="validate.valuablesUnitPrice.errMsg"
              />
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>トン換算係数</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="l-flex start">
                <InputText
                  :value.sync="formEdit.conversionFactorKg"
                  class="l-area sm"
                  tooltip="半角数値で入力してください"
                  inputType="number"
                  :validation="validateNumberOrFloat"
                  :isValid.sync="validate.conversionFactorKg.isValid"
                  :errMsg.sync="validate.conversionFactorKg.errMsg"
                /><span>/ kg</span>
              </div>
              <div class="l-flex start">
                <InputText
                  :value.sync="formEdit.conversionFactorM3"
                  class="l-area sm"
                  tooltip="半角数値で入力してください"
                  inputType="number"
                  :validation="validateNumberOrFloat"
                  :isValid.sync="validate.conversionFactorM3.isValid"
                  :errMsg.sync="validate.conversionFactorM3.errMsg"
                /><span>/ m3</span>
              </div>
              <div class="l-flex start">
                <InputText
                  :value.sync="formEdit.conversionFactorLiter"
                  class="l-area sm"
                  tooltip="半角数値で入力してください"
                  inputType="number"
                  :validation="validateNumberOrFloat"
                  :isValid.sync="validate.conversionFactorLiter.isValid"
                  :errMsg.sync="validate.conversionFactorLiter.errMsg"
                /><span>/ リットル</span>
              </div>
              <div class="l-flex start">
                <InputText
                  :value.sync="formEdit.conversionFactorUnitNumber"
                  class="l-area sm"
                  tooltip="半角数値で入力してください"
                  inputType="number"
                  :validation="validateNumberOrFloat"
                  :isValid.sync="validate.conversionFactorUnitNumber.isValid"
                  :errMsg.sync="validate.conversionFactorUnitNumber.errMsg"
                /><span>/ 個・台</span>
              </div>

            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>運搬費用計算時の対象数量</label>
            </div>
            <div class="c-inputWrap__items">
              <Selectbox :value.sync="formEdit.collectTargetQuantityTypeId">
                <option value=""></option>
                <option
                    v-for="targetQuantityType of targetQuantityTypes"
                    :key="'target-quantity-type-' + targetQuantityType.id"
                    :value="targetQuantityType.id"
                >
                  {{ targetQuantityType.name }}
                </option>
              </Selectbox>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>処分費用計算時の対象数量</label>
            </div>
            <div class="c-inputWrap__items">
              <Selectbox :value.sync="formEdit.disposalTargetQuantityTypeId">
                <option value=""></option>
                <option
                  v-for="targetQuantityType of targetQuantityTypes"
                  :key="'target-quantity-type-' + targetQuantityType.id"
                  :value="targetQuantityType.id"
                >
                  {{ targetQuantityType.name }}
                </option>
              </Selectbox>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>連絡番号</label>
            </div>
            <div class="c-inputWrap__items">
              <div
                v-for="(contactNo, index) in formEdit.routeContactNoList"
                :key="'contact-no-' + index"
                class="l-flex start"
              >
                <InputText
                  class="l-area"
                  :value.sync="contactNo.contactNo"
                  :validation="validateMaxLen"
                  :validateArg="20"
                  inputType="text"
                  tooltip="連絡番号を入力してください"
                  :isValid.sync="validate.routeContactNoList[index].isValid"
                  :errMsg.sync="validate.routeContactNoList[index].errMsg"
                />
                <div
                    class="c-input__addBtn c-btn secondary delete sm"
                    @click="removeContactNoHandler(contactNo, index)"
                >
                  削除
                </div>
              </div>
              <div
                v-if="formEdit.routeContactNoList.length < 3"
                @click="addContactNoHandler()"
                class="c-btn secondary add sm"
              >
                連絡番号を追加
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>備考</label>
            </div>
            <div class="c-inputWrap__items">
              <div 
                  class="l-area"
                  v-for="(remark, index) in formEdit.routeRemarksList"
                  :key="'remarks-' + index"
              >
                <InputText
                  class="l-area"
                  tooltip="備考は50バイト以内で入力してください。"
                  :value.sync="remark.remarks"
                  inputType="text"
                  :validateArg="50"
                  :validation="validateMaxLen"
                  :isValid.sync="validate.routeRemarksList[index].isValid"
                  :errMsg.sync="validate.routeRemarksList[index].errMsg"
                />
                <div
                  class="c-input__addBtn c-btn secondary delete sm"
                  @click="removeTextareaHandler(remark.id)"
                  >削除
                </div>
              </div>
              <div
                v-if="formEdit.routeRemarksList.length < 5"
                @click="addTextareaHandler"
                class="c-btn secondary add sm"
              >
                備考を追加
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>WDS</label>
            </div>
            <div class="c-inputWrap__items">
              <div v-if="!formEdit.wdsFileName">
                <input id="wds" type="file" @change="(e) => uploadWDS(e)" />
                <label class="c-btn secondary upload sm" for="wds">
                  WDSアップロード
                </label>
              </div>
              <div v-else>
                <div class="c-inputWrap__items__cols center sm-mgn">
                  <a @click="downloadWDS(routeInfo.routeId, formEdit.wdsFileName)" class="c-entrustText__text">
                    {{ formEdit.wdsFileName }}
                  </a>
                  <div @click="deleteWDS()" class="c-btn secondary delete sm">
                    削除
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--基本情報ここまで-->
        <!--運搬事業者情報-->
        <div
          v-for="(collector, index) in routeInfo.routeCollectCompanyInfo"
          :key="'collector-' + index"
        >
          <div class="l-contents l-box">
            <div class="l-box">
              <h3 class="c-lead icon">運搬事業者情報{{ index + 1 }}</h3>
            </div>

            <!--運搬事業者が有効の場合-->
            <template v-if="collector.collectCompanyInfo.isActive">
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>事業者名</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ collector.collectCompanyInfo.name | blankText }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬事業所名</label>
                </div>
                <div class="c-inputWrap__items" v-if="collector.collectFactoryInfo.isActive">
                  {{ collector.collectFactoryInfo.name | blankText }}<br />
                  JWNETID(加入者番号)：{{ collector.collectFactoryInfo.jwnetId | blankText }}
                </div>
                <div class="c-inputWrap__items" v-else>-</div>
              </div>

              <!-- 産業・有価廃棄物の許可書情報 -->
              <template v-if="routeInfo.wasteTypeInfo.id !== wasteType.COMMON_WASTE">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可自治体</label>
                  </div>
                  <div class="c-inputWrap__items">
                    積み込み場所 :
                    {{
                      listItem(
                        collector.loadingPlaceList,
                        "municipalityName"
                      ) | blankText
                    }}<br />
                    荷下ろし場所 :
                    {{
                      listItem(
                        collector.unloadingPlaceList,
                        "municipalityName"
                      ) | blankText
                    }}
                  </div>
                </div>

                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    積み込み場所 :
                    <span
                      v-for="loadingLicence of collector.loadingPlaceList"
                      :key="'loading-licence-' + loadingLicence.licenceNo"
                    >
                      {{ loadingLicence.licenceNo }}
                      {{ loadingLicence.licenceExpiredDate | dateJpYMDFormat }}まで<br />
                    </span>
                    <span v-if="collector.loadingPlaceList.length == 0">-</span><br v-if="collector.loadingPlaceList.length == 0" />
                    荷下ろし場所 :
                    <span
                      v-for="unloadingLicence of collector.unloadingPlaceList"
                      :key="'unloading-licence-' + unloadingLicence.licenceNo"
                    >
                      {{ unloadingLicence.licenceNo }}
                      {{ unloadingLicence.licenceExpiredDate | dateJpYMDFormat }}まで<br />
                    </span>
                    <span v-if="collector.unloadingPlaceList.length == 0">-</span>
                  </div>
                </div>
              </template>
              <!-- 一般廃棄物の許可書情報 -->
              <template v-else>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可市区町村</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{
                      listItem(
                        collector.commonLicenceList,
                        "cityName"
                      ) | blankText
                    }}
                  </div>
                </div>

                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <span
                      v-for="commonLicence of collector.commonLicenceList"
                      :key="'common-licence-' + commonLicence.licenceNo"
                    >
                      {{ commonLicence.licenceNo }}
                      {{ commonLicence.licenceExpiredDate | dateJpYMDFormat }}<br />
                    </span>
                    <span v-if="collector.commonLicenceList.length == 0">-</span><br v-if="collector.commonLicenceList.length == 0" />
                  </div>
                </div>
              </template>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬先名</label>
                </div>
                <div class="c-inputWrap__items" v-if="collector.destinationInfo.isActive">
                  {{ collector.destinationInfo.name }}<br />
                  JWNETID(加入者番号)：{{ collector.destinationInfo.jwnetId
                  }}<br />
                  事業場コード：{{ collector.destinationInfo.storeCode }}
                </div>
                <div class="c-inputWrap__items" v-else>-</div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬先住所</label>
                </div>
                <div class="c-inputWrap__items" v-if="collector.destinationInfo.isActive">
                  {{
                    collector.destinationInfo.addressInfo
                      ? collector.destinationInfo.addressInfo.zipCode
                      : "" | zipCodeText
                  }}
                  {{
                    collector.destinationInfo.addressInfo
                      ? collector.destinationInfo.addressInfo.prefecturesName
                      : ""
                  }}
                  {{
                    collector.destinationInfo.addressInfo
                      ? collector.destinationInfo.addressInfo.address
                      : ""
                  }}
                  {{
                    collector.destinationInfo.addressInfo
                      ? collector.destinationInfo.addressInfo.buildingName
                      : ""
                  }}
                </div>
                <div class="c-inputWrap__items" v-else>-</div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>{{ routeInfo.wasteTypeInfo.id !== wasteType.COMMON_WASTE ? '積替保管可能品目' : '対象品目'}}</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ listItem(collector.transshipmentJwnetItem, "name") | blankText }}
                </div>
              </div>
            </template>

            <!--運搬事業者が無効化されたら、全項目は「-」で表示-->
            <template v-else>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>事業者名</label>
                </div>
                <div class="c-inputWrap__items">-</div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬事業所名</label>
                </div>
                <div class="c-inputWrap__items">-</div>
              </div>

              <!-- 産業・有価廃棄物の許可書情報 -->
              <template v-if="routeInfo.wasteTypeInfo.id !== wasteType.COMMON_WASTE">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可自治体</label>
                  </div>
                  <div class="c-inputWrap__items">-</div>
                </div>

                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可番号</label>
                  </div>
                  <div class="c-inputWrap__items">-</div>
                </div>
              </template>
              <!-- 一般廃棄物の許可書情報 -->
              <template v-else>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可市区町村</label>
                  </div>
                  <div class="c-inputWrap__items">-</div>
                </div>

                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可番号</label>
                  </div>
                  <div class="c-inputWrap__items">-</div>
                </div>
              </template>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬先名</label>
                </div>
                <div class="c-inputWrap__items">-</div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬先住所</label>
                </div>
                <div class="c-inputWrap__items">-</div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>{{ routeInfo.wasteTypeInfo.id !== wasteType.COMMON_WASTE ? '積替保管可能品目' : '対象品目'}}</label>
                </div>
                <div class="c-inputWrap__items">-</div>
              </div>
            </template>
          </div>
        </div>
        <!--運搬事業者情報ここまで-->
        <!--処分事業者情報-->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">処分事業者情報</h3>
          </div>
          <!--処分事業者が有効の場合-->
          <template v-if="routeInfo.routeDisposalCompanyInfo.disposalCompanyInfo.isActive">
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>事業者名</label>
              </div>
              <div class="c-inputWrap__items">
                {{ routeInfo.routeDisposalCompanyInfo.disposalCompanyInfo.name | blankText }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>{{ routeInfo.wasteTypeInfo.id !== wasteType.COMMON_WASTE ? '許可自治体' : '許可市区町村'}}</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  listItem(
                    routeInfo.routeDisposalCompanyInfo.disposalLicenceInfo,
                    "municipalityName"
                  ) | blankText
                }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>許可番号</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  listItem(
                    routeInfo.routeDisposalCompanyInfo.disposalLicenceInfo,
                    "licenceNo"
                  ) | blankText
                }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>許可証期限</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  formatDateList(
                    routeInfo.routeDisposalCompanyInfo.disposalLicenceInfo,
                    "licenceExpiredDate"
                  )
                }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分場</label>
              </div>
              <div class="c-inputWrap__items" v-if="routeInfo.routeDisposalCompanyInfo.destinationInfo.isActive">
                {{ routeInfo.routeDisposalCompanyInfo.destinationInfo.name | blankText }}
              </div>
              <div class="c-inputWrap__items" v-else>-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分場住所</label>
              </div>
              <div class="c-inputWrap__items" v-if="routeInfo.routeDisposalCompanyInfo.destinationInfo.isActive">
                {{
                  routeInfo.routeDisposalCompanyInfo.destinationInfo.addressInfo
                    ? routeInfo.routeDisposalCompanyInfo.destinationInfo
                        .addressInfo.zipCode
                    : "" | zipCodeText
                }}
                {{
                  routeInfo.routeDisposalCompanyInfo.destinationInfo.addressInfo
                    ? routeInfo.routeDisposalCompanyInfo.destinationInfo
                        .addressInfo.prefecturesName
                    : ""
                }}
                {{
                  routeInfo.routeDisposalCompanyInfo.destinationInfo.addressInfo
                    ? routeInfo.routeDisposalCompanyInfo.destinationInfo
                        .addressInfo.address
                    : ""
                }}
                {{
                  routeInfo.routeDisposalCompanyInfo.destinationInfo.addressInfo
                    ? routeInfo.routeDisposalCompanyInfo.destinationInfo
                        .addressInfo.buildingName
                    : ""
                }}
              </div>
              <div class="c-inputWrap__items" v-else>-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>対象品目</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  listItem(
                    routeInfo.routeDisposalCompanyInfo.disposalJwnetItem,
                    "name"
                  ) | blankText
                }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>最終処分場</label>
              </div>
              <div class="c-inputWrap__items" v-if="routeInfo.routeDisposalCompanyInfo.destinationInfo.isActive">
                <button
                  class="c-btn secondary func add l-item"
                  @click="isFinalDisposalSiteSelectModalShow = true"
                >
                  最終処分場設定
                </button>
                <ul
                  v-if="finalDisposalSiteList.length"
                >
                  <li
                    v-for="fds in finalDisposalSiteList"
                    :key="fds.finalDisposalSiteId"
                  >
                     <label>{{fds.finalDisposalSiteName}} </label>
                     <label>住所 : {{fds.addressInfo ? fds.addressInfo.prefecturesName : ""}} {{fds.addressInfo ? fds.addressInfo.address : ""}} {{fds.addressInfo ? fds.addressInfo.buildingName : ""}} </label>
                     <label>事業場コード : {{fds.finalDisposalStoreCode}} </label>
                  </li>
                </ul>
              </div>
              <div class="c-inputWrap__items" v-else>-</div>
            </div>
          </template>
          <!--処分事業者情報が無効化されたら、全項目は「-」で表示-->
          <template v-else>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>事業者名</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>{{ routeInfo.wasteTypeInfo.id !== wasteType.COMMON_WASTE ? '許可自治体' : '許可市区町村'}}</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>許可番号</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>許可証期限</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分場</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分場住所</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>対象品目</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>最終処分場</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
          </template>
        </div>
        <!--処分事業者情報ここまで-->

        <!-- 担当者情報 -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">担当者情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>登録担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.registMemberName"
                  inputType="text"
                  placeholder="入力してください"
                  tooltip="登録担当者を入力してください"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.registMemberName.isValid"
                  :errMsg.sync="validate.registMemberName.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>引き渡し担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="formEdit.generateMemberName"
                  inputType="text"
                  placeholder="入力してください"
                  tooltip="引き渡し担当者を入力してください"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.generateMemberName.isValid"
                  :errMsg.sync="validate.generateMemberName.errMsg"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- 受渡確認票 / 一括登録通知配布先 -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">受渡確認票 / 一括登録通知配布先</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>運搬事業者がJWNETで登録/wellfestで登録</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <div class="c-radio">
                    <input
                        id="register-jwnet-true"
                        type="radio"
                        :value="0"
                        v-model="formEdit.collectorRegistType"
                    />
                    <label class="c-radio__label" for="register-jwnet-true">
                      <span class="c-radio__box"></span>JWNETで登録
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                        id="register-wellfest-true"
                        type="radio"
                        :value="1"
                        v-model="formEdit.collectorRegistType"
                    />
                    <label class="c-radio__label" for="register-wellfest-true">
                      <span class="c-radio__box"></span>wellfestで登録
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="routeDeliveryConfirmation.generateDeliveryConfirmMail" class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送付先email（排出）</label>
              </div>
              <div class="c-inputWrap__items">
                {{ routeDeliveryConfirmation.generateDeliveryConfirmMail }}
              </div>
            </div>
            <div v-if="routeDeliveryConfirmation.collectDeliveryConfirmMail" class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送付先email（運搬）</label>
              </div>
              <div class="c-inputWrap__items">
                {{ routeDeliveryConfirmation.collectDeliveryConfirmMail }}
              </div>
            </div>
            <div v-if="routeDeliveryConfirmation.disposalDeliveryConfirmMail" class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送付先email（処分）</label>
              </div>
              <div class="c-inputWrap__items">
                {{ routeDeliveryConfirmation.disposalDeliveryConfirmMail }}
              </div>
            </div>
            <div v-if="routeDeliveryConfirmation.anyMail1" class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送付先email1</label>
              </div>
              <div class="c-inputWrap__items">
                {{ routeDeliveryConfirmation.anyMail1 }}
              </div>
            </div>
            <div v-if="routeDeliveryConfirmation.anyMail2" class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送付先email2</label>
              </div>
              <div class="c-inputWrap__items">
                {{ routeDeliveryConfirmation.anyMail2 }}
              </div>
            </div>
            <div v-if="routeDeliveryConfirmation.anyMail3" class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送付先email3</label>
              </div>
              <div class="c-inputWrap__items">
                {{ routeDeliveryConfirmation.anyMail3 }}
              </div>
            </div>
            <div v-if="routeDeliveryConfirmation.anyMail4" class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送付先email4</label>
              </div>
              <div class="c-inputWrap__items">
                {{ routeDeliveryConfirmation.anyMail4 }}
              </div>
            </div>
            <div v-if="routeDeliveryConfirmation.anyMail5" class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送付先email5</label>
              </div>
              <div class="c-inputWrap__items">
                {{ routeDeliveryConfirmation.anyMail5 }}
              </div>
            </div>
          </div>
        </div>

        <!--ラベル-->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">ノート</h3>
          </div>
          <div
            v-for="(label, index) in formEdit.labelList"
            :key="'label-' + index"
            class="c-inputWrap"
          >
            <div class="c-inputWrap__label">
              <label>{{ label.labelHeader }}</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <InputText
                  :value.sync="label.labelDefaultValue"
                  :validation="validateMaxLen"
                  :validateArg="50"
                  inputType="string"
                  tooltip="値を入力してください"
                  placeholder="値"
                  :isValid.sync="
                    validate.labelList[index].labelDefaultValue.isValid
                  "
                  :errMsg.sync="
                    validate.labelList[index].labelDefaultValue.errMsg
                  "
                />
                <InputText
                  :value.sync="label.labelUnit"
                  :validation="validateMaxLen"
                  :validateArg="50"
                  inputType="string"
                  tooltip="単位を入力してください"
                  placeholder="単位"
                  :isValid.sync="validate.labelList[index].labelUnit.isValid"
                  :errMsg.sync="validate.labelList[index].labelUnit.errMsg"
                  class="sm"
                />
                <div
                  @click="deleteLabel(index)"
                  class="c-btn secondary mini delete"
                >
                  削除
                </div>
              </div>
              <div class="c-inputWrap__items__cols l-item-t" style="display: none !important;">
                <div class="c-radio">
                  <input
                    :id="`label-type-${index}-0`"
                    type="radio"
                    :name="`label-type-${index}`"
                    :value="1"
                    v-model="label.labelTypeId"
                  /><label
                    class="c-radio__label"
                    :for="`label-type-${index}-0`"
                  >
                    <span class="c-radio__box"></span>
                    テキスト
                  </label>
                </div>
                <div class="c-radio">
                  <input
                    :id="`label-type-${index}-1`"
                    type="radio"
                    :name="`label-type-${index}`"
                    :value="2"
                    v-model="label.labelTypeId"
                  /><label
                    class="c-radio__label"
                    :for="`label-type-${index}-1`"
                  >
                    <span class="c-radio__box"></span>
                    数値
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="(label, index) in newLabelList"
            :key="'new-' + index"
            class="c-inputWrap"
          >
            <div class="c-inputWrap__label">
              <InputText
                :value.sync="label.labelHeader"
                :validation="validateMaxLen"
                :validateArg="50"
                :isValid.sync="validate.newLabelList[index].labelHeader.isValid"
                :errMsg.sync="validate.newLabelList[index].labelHeader.errMsg"
                class="sm"
              />
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <InputText
                  :value.sync="label.labelDefaultValue"
                  :validation="validateMaxLen"
                  :validateArg="50"
                  inputType="string"
                  tooltip="値を入力してください"
                  placeholder="値"
                  :isValid.sync="
                    validate.newLabelList[index].labelDefaultValue.isValid
                  "
                  :errMsg.sync="
                    validate.newLabelList[index].labelDefaultValue.errMsg
                  "
                />
                <InputText
                  :value.sync="label.labelUnit"
                  :validation="validateMaxLen"
                  :validateArg="20"
                  inputType="string"
                  tooltip="単位を入力してください"
                  placeholder="単位"
                  :isValid.sync="validate.newLabelList[index].labelUnit.isValid"
                  :errMsg.sync="validate.newLabelList[index].labelUnit.errMsg"
                  class="sm"
                />
                <div
                  @click="deleteNewLabel(index)"
                  class="c-btn secondary mini delete"
                >
                  削除
                </div>
              </div>
              <div class="c-inputWrap__items__cols l-item-t" style="display: none !important;">
                <div class="c-radio">
                  <input
                    :id="`label-type-${index + formEdit.labelList.length}-0`"
                    type="radio"
                    :name="`label-type-${index + formEdit.labelList.length}`"
                    :value="1"
                    v-model="label.labelTypeId"
                  /><label
                    class="c-radio__label"
                    :for="`label-type-${index + formEdit.labelList.length}-0`"
                  >
                    <span class="c-radio__box"></span>
                    テキスト
                  </label>
                </div>
                <div class="c-radio">
                  <input
                    :id="`label-type-${index + formEdit.labelList.length}-1`"
                    type="radio"
                    :name="`label-type-${index + formEdit.labelList.length}`"
                    :value="2"
                    v-model="label.labelTypeId"
                  /><label
                    class="c-radio__label"
                    :for="`label-type-${index + formEdit.labelList.length}-1`"
                  >
                    <span class="c-radio__box"></span>
                    数値
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="formEdit.labelList.length + newLabelList.length < 50"
            class="c-inputWrap"
          >
            <div class="c-inputWrap__label">
              <div @click="addLabel()" class="c-btn secondary mini add">
                ノートを追加
              </div>
            </div>
          </div>
        </div>
        <!--ラベルここまで-->
        <!--自動設定-->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">自動設定</h3>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>代理登録の自動承認</label>
            </div>
            <div class="c-inputWrap__items__cols">
              <div class="c-radio">
                <input
                  id="auto-approval-true"
                  type="radio"
                  name="auto-approval"
                  :value="true"
                  v-model="formEdit.isAutoApproval"
                /><label class="c-radio__label" for="auto-approval-true">
                  <span class="c-radio__box"></span>
                  有
                </label>
              </div>
              <div class="c-radio">
                <input
                  id="auto-approval-false"
                  type="radio"
                  name="auto-approval"
                  :value="false"
                  v-model="formEdit.isAutoApproval"
                /><label class="c-radio__label" for="auto-approval-false">
                  <span class="c-radio__box"></span>
                  無
                </label>
              </div>
            </div>
          </div>

          <!--引き渡し日以降の自動交付-->
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>引き渡し日以降の自動交付</label>
            </div>
            <div>
              <div class="c-inputWrap__items__cols">
                <div class="c-radio">
                  <input
                    id="auto-issue-after-collection-date-true"
                    type="radio"
                    name="auto-issue-after-collection-date"
                    :value="true"
                    v-model="formEdit.isAutoIssueAfterCollectionDate"
                  /><label
                    class="c-radio__label"
                    for="auto-issue-after-collection-date-true"
                  >
                    <span class="c-radio__box"></span>
                    有
                  </label>
                </div>
                <div class="c-radio">
                  <input
                    id="auto-issue-after-collection-date-false"
                    type="radio"
                    name="auto-issue-after-collection-date"
                    :value="false"
                    v-model="formEdit.isAutoIssueAfterCollectionDate"
                  /><label
                    class="c-radio__label"
                    for="auto-issue-after-collection-date-false"
                  >
                    <span class="c-radio__box"></span>
                    無
                  </label>
                </div>
              </div>
              <div
                  v-if="formEdit.isAutoIssueAfterCollectionDate"
                  class="c-inputWrap__items__cols"
              >
                <Selectbox
                    :value.sync="formEdit.autoIssueExecDateTypeId"
                    class="sm"
                >
                  <option value="0">当日</option>
                  <option value="1">1日後</option>
                  <option value="2">2日後</option>
                  <option value="3">3日後</option>
                </Selectbox>
                <InputTime
                    class="sm"
                    :value.sync="formEdit.autoIssueExecTime"
                    :placeholder="'00:00'"
                    :validation="validateJustBlank"
                />
              </div>
            </div>
          </div>
          <!--引き渡し日以降の自動交付-->
          <!--一廃/有価の運搬終了報告要否-->
          <div class="c-inputWrap" :class="{ disabled: isDisableNecessityCommonCollectReportButton }">
            <div class="c-inputWrap__label">
              <label>一廃/有価の運搬終了報告要否</label>
            </div>
            <div class="c-inputWrap__items__cols">
              <div class="c-radio">
                <input
                  id="necessity-common-collect-report-true"
                  type="radio"
                  name="necessity-common-collect-report"
                  :value="true"
                  v-model="formEdit.isNecessityCommonCollectReport"
                /><label
                  class="c-radio__label"
                  for="necessity-common-collect-report-true"
                >
                  <span class="c-radio__box"></span>
                  要
                </label>
              </div>
              <div class="c-radio">
                <input
                  id="necessity-common-collect-report-false"
                  type="radio"
                  name="necessity-common-collect-report"
                  :value="false"
                  v-model="formEdit.isNecessityCommonCollectReport"
                /><label
                  class="c-radio__label"
                  for="necessity-common-collect-report-false"
                >
                  <span class="c-radio__box"></span>
                  否
                </label>
              </div>
            </div>
          </div>
          <!--運搬終了報告時通知有無-->
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>運搬終了報告時通知</label>
            </div>
            <div class="c-inputWrap__items__cols">
              <div class="c-radio">
                <input
                  id="collect-report-alert-true"
                  type="radio"
                  name="collect-report-alert"
                  :value="true"
                  v-model="formEdit.isCollectReportAlert"
                /><label
                  class="c-radio__label"
                  for="collect-report-alert-true"
                >
                  <span class="c-radio__box"></span>
                  有
                </label>
              </div>
              <div class="c-radio">
                <input
                  id="collect-report-alert-false"
                  type="radio"
                  name="collect-report-alert"
                  :value="false"
                  v-model="formEdit.isCollectReportAlert"
                /><label
                  class="c-radio__label"
                  for="collect-report-alert-false"
                >
                  <span class="c-radio__box"></span>
                  無
                </label>
              </div>
            </div>
          </div>
          <!--終了報告後自動交付有無-->
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>終了報告後自動交付</label>
            </div>
            <div class="c-inputWrap__items__cols">
              <div class="c-radio">
                <input
                  id="auto-issue-true"
                  type="radio"
                  name="auto-issue"
                  :value="true"
                  v-model="formEdit.isAutoIssue"
                /><label class="c-radio__label" for="auto-issue-true">
                  <span class="c-radio__box"></span>
                  有
                </label>
              </div>
              <div class="c-radio">
                <input
                  id="auto-issue-false"
                  type="radio"
                  name="auto-issue"
                  :value="false"
                  v-model="formEdit.isAutoIssue"
                /><label class="c-radio__label" for="auto-issue-false">
                  <span class="c-radio__box"></span>
                  無
                </label>
              </div>
            </div>
          </div>

          <!--処分終了報告時通知有無-->
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>処分終了報告時通知</label>
            </div>
            <div class="c-inputWrap__items__cols">
              <div class="c-radio">
                <input
                  id="disposal-report-alert-true"
                  type="radio"
                  name="disposal-report-alert"
                  :value="true"
                  v-model="formEdit.isDisposalReportAlert"
                /><label
                  class="c-radio__label"
                  for="disposal-report-alert-true"
                >
                  <span class="c-radio__box"></span>
                  有
                </label>
              </div>
              <div class="c-radio">
                <input
                  id="disposal-report-alert-false"
                  type="radio"
                  name="disposal-report-alert"
                  :value="false"
                  v-model="formEdit.isDisposalReportAlert"
                /><label
                  class="c-radio__label"
                  for="disposal-report-alert-false"
                >
                  <span class="c-radio__box"></span>
                  無
                </label>
              </div>
            </div>
          </div>

        </div>
        <!--自動設定ここまで-->
      </div>

        <!-- 一般廃棄物処分許可証設定設定モーダル -->
      <FinalDisposalSiteSelectModal
        :isShow.sync="isFinalDisposalSiteSelectModalShow"
        :entrustId="routeInfo.entrustId"
        :default="finalDisposalSiteList"
        @setselectedFinalDisposalSite="setFinalDisposalSite"
      />

      <div class="l-btns l-container" :class="{ disabled: processing }">
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler">戻る</div>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="registerHandler">登録</div>
        </div>
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import InputTime from "@/components/parts/input/InputTime";
import validation from "@/mixin/validation";
import { API_URL, DOMAIN } from "@/const";
import { saveAs } from "file-saver";
import common from "@/mixin/common";

import callApi from "./mixins/callApi";
import formValueEdit from "./mixins/formValueEdit";
import formValidationEdit from "./mixins/formValidationEdit";
import contactNo from "./mixins/contactNo";
import textarea from "./mixins/textarea";
import FinalDisposalSiteSelectModal from "./components/FinalDisposalSiteSelectModal";
import {dateJpYMDFormat} from "@/filter";

export default {
  name: "route-edit",
  components: { 
    InputText, 
    Selectbox, 
    InputTime,
    FinalDisposalSiteSelectModal,
  },
  mixins: [callApi, formValueEdit, formValidationEdit, validation, contactNo, textarea, common,],
  data() {
    return {
      errMsgs: [],
      isFavorite: "",
      newLabelList: [], // 新しく追加したラベルを一時的に保持する
      wdsFile: {},
      wdsFilePath: "",
      processing: false,
      wasteType: DOMAIN.WASTE_TYPE,
      isFinalDisposalSiteSelectModalShow: false,
      finalDisposalSiteList: [],
      routeDeliveryConfirmation: {},
    };
  },
  computed: {
    // {arg: "hoge"}の配列をhogeのみを抽出して"、"つなぎで返す
    listItem: function () {
      return function (objArray, arg) {
        const tempArray = [];
        for (const obj of objArray) {
          tempArray.push(obj[`${arg}`]);
        }
        return tempArray.join("、");
      };
    },
    isDisableNecessityCommonCollectReportButton() {
      return this.routeInfo.wasteTypeInfo.id === DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE && this.routeInfo.manifestTypeInfo.id === DOMAIN.MANIFEST_TYPE.DIGITAL
    },
    isCba() {
      return this.$store.getters["userInfo/isCba"];
    },
    isDisposer() {
      return this.$store.getters["userInfo/isDisposer"];
    },
  },
  mounted() {
    this.routeDetailApi(this.$route.params.id);
    this.wasteUnitApi();
    this.quantityConfirmMembersApi(this.$route.params.id);
    this.shapeTypesApi(this.$route.params.id);
    this.disposalWayTypesApi(this.$route.params.id);
    this.getharmfulTypes()
    this.targetQuantityTypesApi();
  },
  methods: {
    // 許可書の期限リストは"YYYY年M月D日"に変換
    formatDateList(objArray, arg)  {
      const tempArray = [];
      for (const obj of objArray) {
        tempArray.push(dateJpYMDFormat(obj[`${arg}`]));
      }
      if (tempArray.length == 0) return "-";
      return tempArray.join("、");
    },

    // 有害物質名追加
    addHarmfulTypeHandler() {
      this.formEdit.harmfulInfo.push({
        harmfulTypeId: null,
      });
    },
    // 有害物質名削除
    removeHarmfulTypeHandler(harmfulType) {
      this.formEdit.harmfulInfo = this.formEdit.harmfulInfo.filter(
        (ht) => ht != harmfulType
      );
    },

    toggleFavorite() {
      this.isFavorite = !this.isFavorite;
      axios.put(API_URL.ROUTE.FAVORITE + this.$route.params.id, {
        isFavorite: this.isFavorite,
      });
    },
    uploadWDS(e) {
      const files = e.target.files || e.dataTransfer.files;
      this.$data.wdsFile = files[0];

      const params = new FormData();
      params.append("file", this.$data.wdsFile);
      params.append("fileName", this.$data.wdsFile.name);
      axios
        .post(API_URL.COMMON.FILE_UPLOAD, params, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          this.formEdit.wdsFilePath = res.data.filePath;
          this.formEdit.wdsFileName = files[0].name;
        })
        .catch((err) => console.log(err));
    },
    downloadWDS(wasteInfoId, wdsFileName) {
      // 現在はモックしている
      // API_URL.MANIFEST.WDS_DOWNLOAD + String(this.$route.params.id)
      axios
        .get(API_URL.MANIFEST.WDS_DOWNLOAD + wasteInfoId, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type,
          });
          saveAs(blob, wdsFileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteWDS() {
      this.formEdit.wdsFileName = null;
    },
    addLabel() {
      this.newLabelList.push({
        labelHeader: "",
        labelTypeId: DOMAIN.LABEL_TYPE.STRING_TYPE,
        labelDefaultValue: "",
        labelUnit: "",
      });
      this.validate.newLabelList.push({
        labelHeader: { isValid: true, errMsg: "" },
        labelDefaultValue: { isValid: true, errMsg: "" },
        labelUnit: { isValid: true, errMsg: "" },
      });
    },
    deleteLabel(index) {
      this.formEdit.labelList.splice(index, 1);
      this.validate.labelList.splice(index, 1);
    },
    deleteNewLabel(index) {
      this.newLabelList.splice(index, 1);
      this.validate.newLabelList.splice(index, 1);
    },
    setFinalDisposalSite(selectedFinalDisposalSite) {
      this.finalDisposalSiteList = selectedFinalDisposalSite;
    },
    backHandler() {
      this.$router.push(`/route/${this.$route.params.id}`);
    },
    registerHandler() {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止

      var registFlag = this.runValidate(this.formEdit, this.newLabelList);
      if (!registFlag) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        this.formEdit.labelList = this.formEdit.labelList.concat(
          this.newLabelList
        );
        // 詳細名称を全角に変換
        this.formEdit.detailWasteName = this.formEdit.detailWasteName ? this.toFullWidthString(this.formEdit.detailWasteName) : null
        if (this.formEdit.collectUnitPriceList) {
          this.formEdit.collectUnitPriceList.forEach(collect => {
            if (collect.collectPriceWasteUnitTypeId == 0) delete collect.collectPriceWasteUnitTypeId;
          })
        }
        if (this.formEdit.disposalPriceWasteUnitTypeId == 0) delete this.formEdit.disposalPriceWasteUnitTypeId;
        if (this.formEdit.collectTargetQuantityTypeId == 0) delete this.formEdit.collectTargetQuantityTypeId;
        if (this.formEdit.disposalTargetQuantityTypeId == 0) delete this.formEdit.disposalTargetQuantityTypeId;
        if (this.formEdit.limitWasteUnitTypeId == 0) delete this.formEdit.limitWasteUnitTypeId;
        if (this.formEdit.quantityConfirmBusinessTypeId == 0) delete this.formEdit.quantityConfirmBusinessTypeId;
        if (this.formEdit.plansWasteUnitTypeId == 0) delete this.formEdit.plansWasteUnitTypeId;
        if (this.formEdit.shapeTypeId == 0) delete this.formEdit.shapeTypeId;
        var formContactNoList = [];
        this.formEdit.routeContactNoList.forEach(contactNo => {
          if (contactNo.contactNo) {
            formContactNoList.push(contactNo);
          }
        });
        this.formEdit.routeContactNoList = formContactNoList;
        var formRemarksList = [];
        this.formEdit.routeRemarksList.forEach(remark => {
          if (remark.remarks) {
            // 備考を全角に変換する
            remark.remarks = this.toFullWidthString(remark.remarks)
            formRemarksList.push(remark);
          }
        });
        this.formEdit.routeRemarksList = formRemarksList;

        if (this.finalDisposalSiteList.length > 0 ) {
          this.finalDisposalSiteList.forEach((finalDisposalSite)  => {
            this.formEdit.finalDisposalSiteList.push({
              finalDisposalSiteId: finalDisposalSite.finalDisposalSiteId
            })
          });
        } else {
          this.formEdit.finalDisposalSiteList = []
        }

        // harmfulInfoのharmfulTypeIdがnullなら削除
        this.formEdit.harmfulInfo = this.formEdit.harmfulInfo.filter(hf => hf.harmfulTypeId)

        //担当者を全角に変換する
        this.formEdit.registMemberName = this.formEdit.registMemberName ? this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(this.formEdit.registMemberName)) : null
        this.formEdit.generateMemberName = this.formEdit.generateMemberName ? this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(this.formEdit.generateMemberName)) : null

        const wdsFileName = this.formEdit.wdsFileName && this.formEdit.wdsFileName.length > 0 ? this.formEdit.wdsFileName : null;
        const wdsFilePath = this.formEdit.wdsFilePath && this.formEdit.wdsFilePath.length > 0 ? this.formEdit.wdsFilePath : null;
        const requestData = {
          ...this.formEdit,
          wdsFileName: wdsFileName,
          wdsFilePath: wdsFilePath,
        };
        axios
          .put(API_URL.ROUTE.EDIT + this.$route.params.id, requestData)
          .then(() => this.$router.push(`/route/${this.$route.params.id}`))
          .catch((err) => {
            this.newLabelList = [];
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            this.processing = false; // 2重submit解除
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
      }
    },
  },
};
</script>
<style scoped lang="scss">

.u-flex{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.u-w100{
  width: 100%;
}

.u-mgl0{
  margin-left: 0rem;
}

.u-mgb0{
  margin-bottom: 0rem;
}

.u-mgt30{
  margin-top: 3rem;
}

.u-mgr0{
  margin-right: 0rem;
}

.u-mgl10{
  margin-left: 0.625rem;
}

</style>
