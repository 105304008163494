import { QUANITTY_REGEX } from "@/const";

export default {
  data() {
    return {
      validate: {
        routeName: { isValid: false, errMsg: "" },
        detailWasteName: { isValid: false, errMsg: "" },
        plansQuantity: { isValid: true, errMsg: "" },
        limitQuantity: { isValid: true, errMsg: "" },
        shapeQuantity: { isValid: true, errMsg: "" },
        collectUnitPriceList: [
            { isValid: true, errMsg: "" }
        ],
        disposalUnitPrice: { isValid: true, errMsg: "" },
        valuablesUnitPrice: { isValid: true, errMsg: "" },
        routeContactNoList: [
          { isValid: true, errMsg: "" },
        ],
        routeRemarksList: [
          { isValid: true, errMsg: "" },
        ],
        labelList: [],
        newLabelList: [],
        conversionFactorKg: { isValid: true, errMsg: "" },
        conversionFactorM3: { isValid: true, errMsg: "" },
        conversionFactorLiter: { isValid: true, errMsg: "" },
        conversionFactorUnitNumber: { isValid: true, errMsg: "" },
        registMemberName: { isValid: true, errMsg: "" },
        generateMemberName: { isValid: true, errMsg: "" },
      },
    };
  },
  methods: {
    check(formEdit, newLabelList) {
      let isValidForm = true;
      const v = this.validate;
      const numberRegexp = new RegExp(/^[0-9]*$/);
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);
      // 予定数量
      if (formEdit.plansQuantity && !QUANITTY_REGEX.test(formEdit.plansQuantity)) {
        v.plansQuantity.errMsg = "整数5桁、小数点1桁、小数部3桁で入力してください。";
        isValidForm = false;
      }

      if (formEdit.detailWasteName && !v.detailWasteName.isValid) {
        v.detailWasteName.errMsg = "詳細名称は30文字以内で入力してください。";
        isValidForm = false;
      }
      if (formEdit.shapeQuantity) {
        if (!numberRegexp.test(formEdit.shapeQuantity)) {
          v.shapeQuantity.errMsg = "荷姿数量は数字で入力してください。";
          isValidForm = false;
        }
        if (String(formEdit.shapeQuantity).length > 5) {
          v.shapeQuantity.errMsg = "荷姿数量は5桁以内の数値で入力してください。";
          isValidForm = false;
        }
        if (!Number.isInteger(Number(formEdit.shapeQuantity))) {
          v.shapeQuantity.errMsg = "荷姿数量は整数で入力してください。";
          isValidForm = false;
        }
      }

      const unitPriceRegexp = new RegExp(/^([-]?\d{1,8}|0)(\.\d{1,3})?$/);
      v.collectUnitPriceList.forEach((collectUnitPrice, index) => {
        if (formEdit.collectUnitPriceList[index].collectUnitPrice ||
            (formEdit.collectUnitPriceList[index].collectUnitPrice != null &&
                formEdit.collectUnitPriceList[index].collectUnitPrice.toString())) {
          if (!collectUnitPrice.isValid) {
            collectUnitPrice.errMsg = "運搬単価は数字で入力してください。";
            isValidForm = false;
          } else if (!unitPriceRegexp.test(formEdit.collectUnitPriceList[index].collectUnitPrice)) {
            collectUnitPrice.errMsg = "単価は整数8桁、小数点1桁、小数部3桁で入力してください。";
            isValidForm = false;
          }
        } else if (formEdit.collectUnitPriceList[index].collectPriceWasteUnitTypeId) {
          collectUnitPrice.errMsg = "単価も入力してください。";
          isValidForm = false;
        }
      });

      if (formEdit.disposalUnitPrice ||
          (formEdit.disposalUnitPrice != null && formEdit.disposalUnitPrice.toString())) {
        if (!v.disposalUnitPrice.isValid) {
          v.disposalUnitPrice.errMsg = "処分単価は数字で入力してください。";
          isValidForm = false;
        } else if (!unitPriceRegexp.test(formEdit.disposalUnitPrice)) {
          v.disposalUnitPrice.errMsg = "単価は整数8桁、小数点1桁、小数部3桁で入力してください。";
          isValidForm = false;
        }
      } else if (formEdit.disposalPriceWasteUnitTypeId) {
        v.disposalUnitPrice.errMsg = "単価も入力してください。";
        isValidForm = false;
      }

      if (formEdit.valuablesUnitPrice) {
        if (!v.valuablesUnitPrice.isValid) {
          v.valuablesUnitPrice.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        } else if (!unitPriceRegexp.test(formEdit.valuablesUnitPrice)) {
          v.valuablesUnitPrice.errMsg = "単価は整数8桁、小数点1桁、小数部3桁で入力してください。";
          isValidForm = false;
        }
      }

      v.routeContactNoList.forEach((contactNo, index) => {
        var routeContactNo = formEdit.routeContactNoList[index];
        if (encodeURIComponent(routeContactNo.contactNo).replace(/%../g, "x").length > 20) {
          contactNo.errMsg = "連絡番号は20バイト以内で入力してください。";
          isValidForm = false;
        }
        if (!halfAlphaRegexp.test(routeContactNo.contactNo)) {
          contactNo.errMsg = "連絡番号は半角英数記号で入力してください。";
          isValidForm = false;
        }
      });
      v.routeRemarksList.forEach((routeRemark, index) => {
        var remark = formEdit.routeRemarksList[index];
        if (this.countByteByString(this.toFullWidthString(remark.remarks)) > 50) {
          routeRemark.errMsg = "備考は50バイト以内で入力してください。";
          isValidForm = false;
        }
      });
      v.labelList.forEach((label, index) => {
        if (!label.labelHeader.isValid) {
          label.labelHeader.errMsg = "ノートヘッダーは必須入力です。";
          isValidForm = false;
        }
        var labelEdit = formEdit.labelList[index];
        if (labelEdit.labelTypeId == 2) {
          if (!numberRegexp.test(labelEdit.labelDefaultValue)) {
            label.labelDefaultValue.errMsg = "数値を選択している場合、ノート初期値は数値で入力してください。";
            isValidForm = false;
          }
        }
        if (labelEdit.labelHeader != null) {
          if (labelEdit.labelHeader.length > 50) {
            label.labelHeader.errMsg = "ノートヘッダーは50桁以内で入力してください。";
            isValidForm = false;
          }
        }
        if (labelEdit.labelDefaultValue != null) {
          if (labelEdit.labelDefaultValue.length > 50) {
            label.labelDefaultValue.errMsg = "ノート初期値は50桁以内で入力してください。";
            isValidForm = false;
          }
        }
        if (labelEdit.labelUnit != null) {
          if (labelEdit.labelUnit.length > 20) {
            label.labelUnit.errMsg = "ノート単位は20桁以内で入力してください。";
            isValidForm = false;
          }
        }
        if (labelEdit.labelTypeId != 1 && labelEdit.labelTypeId != 2) {
          label.labelDefaultValue.errMsg = "テキストか数値を選択してください。";
          isValidForm = false;
        }
      });
      v.newLabelList.forEach((newLabel, index) => {
        if (!newLabel.labelHeader.isValid) {
          newLabel.labelHeader.errMsg = "ノートヘッダーは必須入力です。";
          isValidForm = false;
        }
        var newLabelEdit = newLabelList[index];
        if (newLabelEdit.labelTypeId == 2) {
          if (!numberRegexp.test(newLabelEdit.labelDefaultValue)) {
            newLabel.labelDefaultValue.errMsg = "数値を選択している場合、ノート初期値は数値で入力してください。";
            isValidForm = false;
          }
        }
        if (newLabelEdit.labelHeader != null) {
          if (newLabelEdit.labelHeader.length > 50) {
            newLabel.labelHeader.errMsg = "ノートヘッダーは50桁以内で入力してください。";
            isValidForm = false;
          }
        }
        if (newLabelEdit.labelDefaultValue != null) {
          if (newLabelEdit.labelDefaultValue.length > 50) {
            newLabel.labelDefaultValue.errMsg = "ノート初期値は50桁以内で入力してください。";
            isValidForm = false;
          }
        }
        if (newLabelEdit.labelUnit != null) {
          if (newLabelEdit.labelUnit.length > 20) {
            newLabel.labelUnit.errMsg = "ノート単位は20桁以内で入力してください。";
            isValidForm = false;
          }
        }
        if (newLabelEdit.labelTypeId != 1 && newLabelEdit.labelTypeId != 2) {
          newLabel.labelDefaultValue.errMsg = "テキストか数値を選択してください。";
          isValidForm = false;
        }
      });

      if (formEdit.registMemberName) {
        if (formEdit.registMemberName.length > 12) {
          v.registMemberName.errMsg = "登録担当者は最大12文字で入力してください。";
          isValidForm = false;
        }
      };

      if (formEdit.generateMemberName) {
        if (formEdit.generateMemberName.length > 12) {
          v.generateMemberName.errMsg = "引き渡し担当者は最大12文字で入力してください。";
          isValidForm = false;
        }
      };

      return isValidForm;
    },

    runValidate(formEdit, newLabelList) {
      this.validate.collectUnitPriceList.forEach((collectUnitPrice) => {
        collectUnitPrice.errMsg = ""
      });
      this.validate.disposalUnitPrice = { isValid: true, errMsg: "" };
      let isValidForm = false;
      isValidForm = this.check(formEdit, newLabelList);

      return isValidForm;
    },
  },
};
