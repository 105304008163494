export default {
  data() {
    return {
      formEdit: {
        routeName: "",
        detailWasteName: "",
        isChangeStore: false,
        plansQuantity: 0,
        plansWasteUnitTypeId: 0,
        limitQuantity: 0,
        limitWasteUnitTypeId: 0,
        quantityConfirmBusinessTypeId: 0,
        shapeTypeId: 0,
        shapeQuantity: 0,
        disposalWayTypeId: 0,
        harmfulInfo: [
          {
            harmfulTypeId: 0
          }
        ],
        collectUnitPriceList: [
          {
            sectionNo: 1,
            collectUnitPrice: 0,
            collectPriceWasteUnitTypeId: 0
          }
        ],
        disposalUnitPrice: 0,
        disposalPriceWasteUnitTypeId: 0,
        valuablesUnitPrice: 0,
        conversionFactorKg: 0,
        conversionFactorM3: 0,
        conversionFactorLiter: 0,
        conversionFactorUnitNumber: 0,
        collectTargetQuantityTypeId: 0,
        disposalTargetQuantityTypeId: 0,
        routeContactNoList: [
          { 
            id: null,
            contactNo: ""
          }
        ],
        routeRemarksList: [
          {
            id: null,
            remarks: ""
          }
        ],
        wdsFilePath: "",
        wdsFileName: "",
        finalDisposalSiteList: [],
        labelList: [],
        isAutoApproval: true,
        isAutoIssueAfterCollectionDate: true,
        autoIssueExecDateTypeId: 0,
        autoIssueExecTime: "",
        isAutoIssue: true,
        isNecessityCommonCollectReport: null,
        isCollectReportAlert: true,
        isDisposalReportAlert: true,
        isActive: true,
        registMemberName: "",
        generateMemberName: "",
        collectorRegistType: 0,
      },
    };
  },
};
