export default {
  methods: {
    // 連絡番号追加
    addContactNoHandler() {
      if (this.formEdit.routeContactNoList.length >= 3) return;
      this.formEdit.routeContactNoList.push({ contactNo: "" });

      //バリデーションも追加
      this.validate.routeContactNoList.push({ isValid: true, errMsg: "" });
    },
    // 連絡番号削除
    removeContactNoHandler(contactNo, index) {
      this.formEdit.routeContactNoList = this.formEdit.routeContactNoList.filter(
        (cn) => cn != contactNo
      );

      //バリデーションも削除
      this.validate.routeContactNoList = this.validate.routeContactNoList.filter(
        (_cv, i) => i != index
      );
    },
  },
};
