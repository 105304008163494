export default {
  methods: {
    // 備考を追加
    addTextareaHandler() {
      if (this.formEdit.routeRemarksList >= 5) return;
      let newId;
      if (
        this.formEdit.routeRemarksList == null ||
        this.formEdit.routeRemarksList.length === 0
      ) {
        newId = 1;
      } else {
        newId =
          this.formEdit.routeRemarksList[this.formEdit.routeRemarksList.length - 1].id +
          1;
      }
      this.formEdit.routeRemarksList.push({
        id: newId,
        remarks: "",
      });
      this.validate.routeRemarksList.push({
        isValid: true,
        errMsg: ""
      });
    },
    // 備考削除
    removeTextareaHandler(id) {
      this.formEdit.routeRemarksList = this.formEdit.routeRemarksList.filter(
        (ta) => ta.id != id
      );
      this.validate.routeRemarksList.pop();
    },
  },
};
